import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import axiosInstance from "utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MyIcon from "./assets/icons/online.png"; // Importing the online icon
import useAxiosInstance from "utils/axios";

const OnlineUser: React.FC = () => {
  const [onlineUsers, setOnlineUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { entityId } = useSelector((state: any) => state.app.allData);
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL;
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    const url = `${apiURL}/api/onlineuser/?entityId=${entityId}`;

    axiosInstance
      .get(url)
      .then((response) => {
        console.log("Success:", response.data);
        setOnlineUsers(response.data);

        // Dispatching notification messages based on the number of online users
        if (response.data.length === 0) {
          dispatch(
            uiActions.updateNotification({
              message: "Warning: No users are online!",
              level: "warning",
            })
          );
        } else {
          dispatch(
            uiActions.updateNotification({
              message: `${response.data.length} user(s) online.`,
              level: "success",
            })
          );
        }
      })
      .catch((error) => {
        console.error(
          "Error:",
          error.response ? error.response.data : error.message
        );
        dispatch(
          uiActions.updateNotification({
            message: "Failed to fetch online users.",
            level: "error",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [apiURL, entityId, dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {/* Header */}
      <Typography variant="h4" component="h2" sx={{ color: "#EE854E", mb: 2 }}>
        Online Users
      </Typography>

      {/* Card */}
      <Card
        sx={{
          backgroundColor: "#F3ECE6", // Card background color
          width: "90%", // Responsive width
          maxWidth: "600px", // Maximum width for the card
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Optional shadow for the card
        }}
      >
        <CardContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {onlineUsers.length > 0 && (
                <>
                  <Typography
                    variant="h6"
                    component="p"
                    sx={{ color: "#008000", textAlign: "center", mb: 2 }}
                  >
                    {/* {onlineUsers.length} user(s) online */}
                  </Typography>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {onlineUsers.map((user, index) => (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "8px 0",
                        }}
                      >
                        <img
                          src={MyIcon}
                          alt="Online Icon"
                          style={{
                            width: 30,
                            height: 30,
                            marginRight: "8px",
                          }}
                        />
                        {user}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default OnlineUser;
