import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import styles from "./assets/scss/ForgetPass.module.css";
import { Navigate, useNavigate } from "react-router-dom";
import useAxiosInstance from "utils/axios";

const ForgetPass: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const navigate = useNavigate();
  const axiosInstance = useAxiosInstance();
  const [alertSeverity, setAlertSeverity] = useState<
    "success" | "error" | "warning"
  >("success");
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const apiURL = process.env.REACT_APP_API_URL;

  const handleSubmit = async () => {
    if (!email) {
      setMessage("Please enter a valid email.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true); // Set loading state to true before API call

    try {
      const response = await axiosInstance.post(
        `${apiURL}/api/send_temp_password/`,
        {
          email: email.toLowerCase(),
        }
      );

      if (response.data && response.data.isOk === false) {
        if (response.data.code === "USER_DOES_NOT_EXIST") {
          setMessage(response.data.message);
          setAlertSeverity("warning");
          setOpenSnackbar(true);
        } else {
          setMessage("An unexpected error occurred.");
          setAlertSeverity("error");
          setOpenSnackbar(true);
        }
      } else if (response.data && response.data.message) {
        setMessage(response.data.message);
        setAlertSeverity("success");
        setOpenSnackbar(true);
        navigate("/Login");
      } else {
        setMessage("Failed to send a temporary password.");
        setAlertSeverity("error");
        setOpenSnackbar(true);
      }

      setEmail(""); // Reset the email field after processing
    } catch (error) {
      console.error("Error sending temporary password:", error);
      setMessage("Error occurred while sending the temporary password.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false); // Set loading state to false after API call finishes
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Card className={styles.card}>
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <h2 style={{ color: "#296354" }}>Forgot Password</h2>
          <TextField
            label="Enter your email"
            variant="outlined"
            type="email"
            value={email}
            onChange={handleEmailChange}
            fullWidth
            style={{ marginBottom: "20px" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!email} // Disable the button until the email is entered
            style={{ marginBottom: "20px", backgroundColor: "#ee854e" }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              "Send Temporary Password"
            )}
          </Button>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert onClose={handleCloseSnackbar} severity={alertSeverity}>
              {message}
            </Alert>
          </Snackbar>
        </div>
      </CardContent>
    </Card>
  );
};

export default ForgetPass;
