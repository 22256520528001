import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axiosInstance from "utils/axios";
import styles from "./assets/css/All.module.css";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageContainer from "component/page-container/page-container";
import useAxiosInstance from "utils/axios";

interface Member {
  member_id: number;
  member_username: string;
  member_email: string;
  member_role: string;
}

interface Project {
  project_id: number;
  project_name: string;
  members: Member[];
}

const AllProjects: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const apiURL = process.env.REACT_APP_API_URL || "";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId, entityId } = useSelector((state: any) => state.app.allData);
  const axiosInstance = useAxiosInstance();

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.post(`${apiURL}/api/projects/`, {
        entity_id: entityId,
      });
      if (response.data.isOk && response.data.code === "SUCCESS") {
        setProjects(response.data.projects);
      }
    } catch (error) {
      console.error("Error fetching projects", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [apiURL]);

  const handleEdit = (project: Project) => {
    navigate(`../Admin/edit-project`, {
      state: {
        projectName: project.project_name,
        members: project.members,
        id: project.project_id,
        memberIds: project.members.map((member) => member.member_id),
      },
    });
  };

  const handleClone = async (project_id: number) => {
    try {
      const response = await axiosInstance.post("/api/duplicate-project/", {
        project_id,
      });
      if (response.data.isOk) {
        console.log("Project cloned successfully");
        fetchProjects();
      } else {
        console.error("Error cloning project:", response.data.message);
      }
    } catch (error) {
      console.error("Error cloning project:", error);
    }
  };

  const handleDelete = async (project_id: number) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this project?"
    );
    if (!confirmed) return;

    try {
      const response = await axiosInstance.post(
        `${apiURL}/api/delete-project/`,
        {
          project_id: project_id,
        }
      );
      const { isOk, message } = response.data;
      if (isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "Project deleted successfully",
            level: "success",
          })
        );
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.project_id !== project_id)
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "Failed to delete project",
            level: "error",
          })
        );
      }
    } catch (error: any) {
      console.error("Error during deletion:", error);
      if (error.response) {
        dispatch(
          uiActions.updateNotification({
            message:
              error.response.data.message ||
              "An error occurred while deleting the project",
            level: "error",
          })
        );
      }
    }
  };

  return (
    <PageContainer>
      <Card className={styles.card}>
        <CardContent>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table className={styles.table}>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell className={styles.tableHeadCell}>
                    Projects
                  </TableCell>
                  <TableCell className={styles.tableHeadCell}>
                    Members
                  </TableCell>
                  <TableCell className={styles.tableHeadCell}>Edit</TableCell>
                  <TableCell className={styles.tableHeadCell}>Clone</TableCell>
                  <TableCell className={styles.tableHeadCell}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((project) => (
                  <TableRow
                    key={project.project_id}
                    className={styles.tableBodyRow}
                  >
                    <TableCell
                      className={styles.tableCell}
                      data-label="Projects"
                    >
                      {project.project_name}
                    </TableCell>
                    <TableCell
                      className={styles.tableCell}
                      data-label="Members"
                    >
                      {project.members
                        .map((member) => member.member_username)
                        .join(", ")}
                    </TableCell>
                    <TableCell className={styles.tableCell} data-label="Edit">
                      <Button
                        variant="contained"
                        className={styles.editButton}
                        onClick={() => handleEdit(project)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell className={styles.tableCell} data-label="Clone">
                      <Button
                        variant="contained"
                        className={styles.cloneButton}
                        onClick={() => handleClone(project.project_id)}
                      >
                        Clone
                      </Button>
                    </TableCell>
                    <TableCell className={styles.tableCell} data-label="Delete">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDelete(project.project_id)}
                        style={{ backgroundColor: "red", color: "white" }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default AllProjects;
