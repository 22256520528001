import axios from "axios";
import { useSelector } from "react-redux";

const apiURL = process.env.REACT_APP_API_URL;

const useAxiosInstance = () => {
  const app = useSelector((state: any) => state.app.allData);

  const axiosInstance = axios.create({ baseURL: apiURL });

  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = "application/json";
      const token = localStorage.getItem("token");

      // If token exists, include it in the header
      config.headers["Authorization"] = `user_id= ${app.userId}`;

      // Optionally, include the user_id as well for any specific need

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response?.status === 403 &&
        error.response?.data?.error === "User is blocked."
      ) {
        // Trigger logout and redirect to login
        localStorage.removeItem("token");
        window.location.href = "/Login";
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default useAxiosInstance;
