import { Button, TextField, CircularProgress } from "@mui/material";
import axiosInstance from "utils/axios";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import styles from "./assets/css/Admin.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageContainer from "component/page-container/page-container";
import useAxiosInstance from "utils/axios";
const CreateUsers: React.FC = () => {
  const apiURL = process.env.REACT_APP_API_URL || ""; // Ensure fallback if variable is undefined

  const dispatch = useDispatch();
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [buttonText, setButtonText] = useState<string>("ADD");
  const [loading, setLoading] = useState<boolean>(false);
  const app = useSelector((state: any) => state.app.allData);
  const axiosInstance = useAxiosInstance();

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email.toLowerCase());
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      let newRole: string;

      if (username === "admin" && password === "admin") {
        newRole = "admin";
      } else {
        newRole = "user";
      }

      const response = await axiosInstance.post(`${apiURL}/api/add_member/`, {
        username,
        email,
        role: newRole,
        entityId: app.entityId,
      });

      const { isOk, message } = response.data;
      if (isOk) {
        setButtonText("ADDED");
        dispatch(
          uiActions.updateNotification({
            message,
            level: "success",
          })
        );
        // Reset form on successful addition
        setUsername("");
        setEmail("");
        setButtonText("ADD");
      } else {
        dispatch(
          uiActions.updateNotification({
            message,
            level: "error",
          })
        );
      }
    } catch (error: any) {
      console.error("Error during user creation:", error);
      if (error.response) {
        dispatch(
          uiActions.updateNotification({
            message: error.response.data.error,
            level: "error",
          })
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      {" "}
      <Card className={styles.card}>
        <CardContent>
          <TextField
            className={styles.text}
            label="Username:"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <br />
          <TextField
            className={styles.text}
            label="Email:"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />

          <Button
            className={styles.btn3}
            variant="contained"
            color="success"
            onClick={handleLogin}
            disabled={
              !username ||
              !validateEmail(email) ||
              buttonText === "ADDED" ||
              loading
            }
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              buttonText
            )}
          </Button>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default CreateUsers;
