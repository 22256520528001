import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavigationMenuComponent from "./navigation-menu";

enum Role {
  Admin = "admin",
  User = "user",
}

interface IProps {}

const NavigationMenu = () => {
  const app = useSelector((state: any) => state.app.allData); // Redux state
  const [role, setRole] = useState<Role | null>(null); // Local state for role

  // Synchronize role from Redux or localStorage
  useEffect(() => {
    const storedRole = localStorage.getItem("role");

    if (app?.role) {
      setRole(app.role); // If Redux state has role, use it
      localStorage.setItem("role", app.role); // Update localStorage
    } else if (storedRole) {
      setRole(storedRole as Role); // Fallback to localStorage
    }
  }, [app]);

  // Ensure role is loaded before rendering
  if (!role) return null;

  // Render based on the role
  if (role === Role.Admin) {
    return (
      <NavigationMenuComponent
        navMenuItems={[
          {
            id: "1",
            title: "Time Tracking",
            width: "60%",
            backgroundColor: "#2C6556",
            pages: [
              { path: "/Admin/Tracker", title: "Tracker" },
              { path: "/Admin/ManualTracker", title: "Manual Tracker" },
            ],
          },
          {
            id: "2",
            title: "Projects",
            width: "50%",
            backgroundColor: "#3D7164",
            pages: [
              { path: "/Admin/AssignProject", title: "Assign Project" },
              { path: "/Admin/CreateProject", title: "Create Project" },
              { path: "/Admin/ProjectReport", title: "Project Report" },
              { path: "/Admin/All", title: "All Projects" },
            ],
          },
          {
            id: "3",
            title: "Users",
            width: "40%",
            backgroundColor: "#4D7D71",
            pages: [
              { path: "/Admin/Approve", title: "Approve Time" },
              { path: "/Admin/CreateUser", title: "Create User" },
              { path: "/Admin/UserReport", title: "User Report" },
              { path: "/Admin/OnlineUser", title: "Online User" },
              { path: "/Admin/AllUsers", title: "All Users" },
            ],
          },
          {
            id: "4",
            title: "Settings",
            width: "30%",
            backgroundColor: "#80A29A",
            pages: [
              { path: "/Admin/Profile", title: "Profile" },
              { path: "/Admin/Reset", title: "Reset" },
            ],
          },
        ]}
      />
    );
  } else if (role === Role.User) {
    return (
      <NavigationMenuComponent
        navMenuItems={[
          {
            id: "1",
            title: "Time Tracking",
            width: "60%",
            backgroundColor: "#2C6556",
            pages: [
              { path: "/User/Tracker", title: "Tracker" },
              { path: "/User/ManualTracker", title: "Manual Tracker" },
            ],
          },
          {
            id: "2",
            title: "My Report",
            width: "50%",
            backgroundColor: "#3D7164",
            pages: [{ path: "/User/MyReports", title: "My Reports" }],
          },
          {
            id: "3",
            title: "Profile",
            width: "40%",
            backgroundColor: "#4D7D71",
            pages: [{ path: "/User/Profile", title: "Profile" }],
          },
        ]}
      />
    );
  }

  return null;
};

export default NavigationMenu;
