import React, { useState, useEffect, MouseEvent } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import styles from "./assets/scss/time-tracking-manual.module.css";
import axiosInstance from "utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import DateTime from "../dateTime/DateTime";
import { _DateTimeToLocalISOString } from "../../utils/date";
import { Card, CardContent, Typography } from "@mui/material";
import Select from "@mui/material/Select";
import PageContainer from "component/page-container/page-container";
import useAxiosInstance from "utils/axios";

interface Project {
  id: number;
  name: string;
}

interface QueriedProject {
  project_name: string;
  total_duration: string;
}

interface RootState {
  app: {
    allData: {
      userId: number;
      userName: string;
    };
  };
}

const ManualTracker: React.FC = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  // Set startTime to one hour before current time and endTime to current time
  const currentTime = new Date();
  const [startTime, setStartTime] = useState<Date>(
    new Date(currentTime.getTime() - 3600000)
  ); // 1 hour ago
  const [endTime, setEndTime] = useState<Date>(currentTime); // Current time

  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [queriedProjects, setQueriedProjects] = useState<QueriedProject[]>([]);
  const apiURL = process.env.REACT_APP_API_URL || "";
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("token")
  );
  const [Task, setTask] = useState<string>("");
  const axiosInstance = useAxiosInstance();

  const { userId, userName } = useSelector(
    (state: RootState) => state.app.allData
  );

  const handleQuerySelectorClick = async () => {
    try {
      const response = await axiosInstance.get(
        apiURL + `/api/member/${userId}/projects/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.projects) {
        setQueriedProjects(response.data.projects);
      }
    } catch (error: any) {
      console.error("Error fetching projects:", error.message);
    }
    setShowTable(true);
  };

  // const handleClick = (event: MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  //   setOpen(true);
  // };

  // const handleClose = (
  //   projectId: number | null,
  //   projectName: string | null
  // ) => {
  //   setTimeout(() => {
  //     setAnchorEl(null);
  //     setOpen(false);
  //     if (projectId && projectName) {
  //       setSelectedProject({ id: projectId, name: projectName });
  //     }
  //   }, 100);
  // };

  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async () => {
    setLoading(true); // Set loading to true
    const startUTCTime = _DateTimeToLocalISOString(startTime);
    const endUTCTime = _DateTimeToLocalISOString(endTime);

    const duration =
      new Date(endTime).getTime() - new Date(startTime).getTime();

    if (duration > 0 && duration <= 5 * 3600000) {
      const formattedDuration = formatElapsedTime(duration);

      const data = {
        userId: userId,
        projectId: selectedProject?.id,
        startTime: startUTCTime,
        duration: formattedDuration,
        endTime: endUTCTime,
        task: Task,
        auto: "manual",
      };

      try {
        const response = await axiosInstance.post(
          apiURL + "/api/add_manual_time/",
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { isOk, message } = response.data;
        if (isOk) {
          // Reset fields after successful submission
          setStartTime(new Date(currentTime.getTime() - 3600000)); // Reset to 1 hour ago
          setEndTime(currentTime); // Reset to current time
          setTask("");
          dispatch(
            uiActions.updateNotification({
              message: "Time added successfully",
              level: "success",
            })
          );
        } else {
          dispatch(
            uiActions.updateNotification({
              message: message,
              level: "error",
            })
          );
        }

        handleQuerySelectorClick();
      } catch (error: any) {
        console.error("Error sending time tracking data:", error.message);
      } finally {
        setLoading(false); // Reset loading state
      }
    } else {
      dispatch(
        uiActions.updateNotification({
          message: "Max hours to enter manually is 5 hrs",
          level: "error",
        })
      );
      setLoading(false); // Reset loading state
    }
  };
  useEffect(() => {
    const fetchMemberProjects = async () => {
      try {
        const response = await axiosInstance.get(
          apiURL + `/api/member-projects/${userId}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data && response.data.data) {
          const projects = response.data.data;
          setProjectList(projects);
        }
      } catch (error: any) {
        console.error("Error fetching member projects:", error.message);
      }
    };

    fetchMemberProjects();
  }, [userId, token]);

  const formatElapsedTime = (time: number) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const _StartTimeChangeHandler = (evt: any) => {
    const newStartTime = new Date(evt.$d);

    // Ensure that start time is not in the future
    const currentTime = new Date();
    if (newStartTime > currentTime) {
      dispatch(
        uiActions.updateNotification({
          message: "Start time cannot be in the future. Resetting to default.",
          level: "error",
        })
      );
      setStartTime(new Date(currentTime.getTime() - 3600000)); // Reset to 1 hour ago
      return;
    }

    setStartTime(newStartTime);

    // If the new start time is after the current end time, adjust the end time
    if (newStartTime > endTime) {
      setEndTime(new Date(newStartTime.getTime() + 3600000)); // Default to 1 hour later
    }
  };

  const _EndTimeChangeHandler = (evt: any) => {
    const newEndTime = new Date(evt.$d);

    // Ensure that end time is not in the future
    const currentTime = new Date();
    if (newEndTime > currentTime) {
      dispatch(
        uiActions.updateNotification({
          message: "End time cannot be in the future. Resetting to default.",
          level: "error",
        })
      );
      setEndTime(currentTime); // Reset to current time
      return;
    }

    setEndTime(newEndTime);
  };

  return (
    <>
      <PageContainer>
        <Card
          style={{
            backgroundColor: "#F3ECE6",
            padding: "16px",
            borderRadius: "8px",
            width: "89%",
            marginLeft: "6% ",
            marginTop: "80px",
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              style={{ marginBottom: "16px", textAlign: "center" }}
            >
              <Select
                value={selectedProject ? selectedProject.id : ""}
                onChange={(e) => {
                  const projectId = e.target.value;
                  const project = projectList.find((p) => p.id === projectId);
                  setSelectedProject(project || null);
                }}
                displayEmpty
                className={styles.menu}
              >
                <MenuItem value="" disabled>
                  Select Project
                </MenuItem>
                {projectList.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
              <input
                className={styles.input2}
                type="text"
                value={Task}
                onChange={(e) => setTask(e.target.value)}
                placeholder="Task"
              />
              <div className={styles.dateTimeContainer}>
                <DateTime
                  label="Start time"
                  timeChangeHandler={_StartTimeChangeHandler}
                  dateTime={startTime}
                />
                <DateTime
                  label="End time"
                  timeChangeHandler={_EndTimeChangeHandler}
                  dateTime={endTime}
                  minDateTime={startTime}
                />
              </div>

              <Button
                className={styles.btn}
                variant="contained"
                color="success"
                onClick={handleSubmit}
                disabled={
                  loading ||
                  !startTime ||
                  !endTime ||
                  !selectedProject ||
                  !Task ||
                  !userId
                }
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
              {/* {showTable && (
                <table className={styles.tb}>
                  <thead>
                    <tr className={styles.tableHeader}>
                      <th>Project Name</th>
                      <th>Total Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {queriedProjects.map((project, index) => (
                      <tr key={index} className={styles.tableRow}>
                        <td>{project.project_name}</td>
                        <td>{project.total_duration.slice(4)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )} */}
            </Typography>
          </CardContent>
        </Card>
      </PageContainer>
    </>
  );
};

export default ManualTracker;
