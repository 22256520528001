import React from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
// icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// components
import FormProvider from "../../component/hook-form/form-provider";
import RHFTextField from "../../component/hook-form/rhf-text-field";
// routing
import { useNavigate } from "react-router-dom";
// utils
import axiosInstance from "utils/axios";
// hooks
import { useBoolean } from "../../hooks/use-boolean";
// Redux
import { useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import { appActions } from "../../store/app/app";
import useAxiosInstance from "utils/axios";

export default function JwtRegisterView() {
  const navigate = useNavigate();
  const password = useBoolean();
  const dispatch = useDispatch();
  const axiosInstance = useAxiosInstance();

  const RegisterSchema = Yup.object().shape({
    entityName: Yup.string().required("Entity name required").min(3),
    userName: Yup.string().required("User name required").min(3),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string().required("Password is required").min(8),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const defaultValues = {
    entityName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    gender: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await axiosInstance.post("api/register_entity/", {
        email: data.email,
        password: data.password,
        entityName: data.entityName,
        userName: data.userName,
      });
      const {
        isOk,
        message,
        accessToken,
        entityId,
        userName,
        userId,
        entityName,
        email,
      } = response.data;
      if (isOk) {
        dispatch(
          appActions.setAppData({
            userId: userId,
            entityId: entityId,
            entityName: entityName,
            userName: userName,
            email: email,
          })
        );

        navigate("/verify");
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      }
    } catch (error: any) {
      console.error(error);
      reset();
      dispatch(
        uiActions.updateNotification({
          message: error.message,
          level: "error",
        })
      );
    }
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mt: 5, position: "relative" }}>
      <Stack direction="row" spacing={0.5}>
        <Typography
          style={{
            font: "normal normal bold 20px/40px Nunito Sans Normal",
            color: "#467769",
          }}
          variant="body2"
        >
          Already have an account?
        </Typography>
        <Link
          href="/Login"
          variant="subtitle2"
          style={{
            color: "#0F363B",
            textDecorationColor: "black",
            font: "normal normal bold 20px/40px Nunito Sans Normal",
          }}
        >
          Sign in
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        color: "text.secondary",
        mt: 2.5,
        typography: "caption",
        textAlign: "center",
      }}
    >
      By signing up, I agree to{" "}
      <Link underline="always" color="text.primary">
        Terms of Service
      </Link>{" "}
      and{" "}
      <Link underline="always" color="text.primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5}>
        <RHFTextField
          name="entityName"
          label="Entity Name"
          sx={{ backgroundColor: "#2963545C" }}
        />
        <RHFTextField
          name="userName"
          label="User Name"
          sx={{ backgroundColor: "#2963545C" }}
        />
        <RHFTextField
          name="email"
          label="Email address"
          sx={{ backgroundColor: "#2963545C" }}
        />
        <RHFTextField
          name="password"
          label="Password"
          sx={{ backgroundColor: "#2963545C" }}
          type={password.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  {password.value ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <RHFTextField
          name="confirmPassword"
          label="Confirm Password"
          sx={{ backgroundColor: "#2963545C" }}
          type={password.value ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={password.onToggle} edge="end">
                  {password.value ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            backgroundColor: "#EE854E",
            "&:hover": { backgroundColor: "#EE854E" },
          }}
        >
          Create account
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <div
      style={{
        maxWidth: "90%",
        width: "400px",
        margin: "50px auto",
        padding: "20px",
        backgroundColor: "#F3ECE6",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {renderForm}
      {renderHead}
      {renderTerms}
    </div>
  );
}
