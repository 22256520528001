import Button from "@mui/material/Button";
import axiosInstance from "utils/axios";
import * as React from "react";
import { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styles from "./assets/css/Approve.module.css";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../../store/ui/ui-slice";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageContainer from "component/page-container/page-container";
import useAxiosInstance from "utils/axios";

interface User {
  id: number;
  username: string;
}

interface Project {
  id: number;
  name: string;
}

const Approve: React.FC = () => {
  const app = useSelector((state: any) => state.app.allData);
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL || "";

  const [memberAnchorEl, setMemberAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [projectAnchorEl, setProjectAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [usernames, setUsernames] = useState<User[]>([]);
  const [memberIdMap, setMemberIdMap] = useState<{ [key: string]: number }>({});
  const [projectIdMap, setProjectIdMap] = useState<{ [key: string]: number }>(
    {}
  );
  const [selectedMembers, setSelectedMembers] = useState<User[]>([]);
  const [buttonText, setButtonText] = useState<string>("ADD");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickMember = (event: React.MouseEvent<HTMLElement>) => {
    setMemberAnchorEl(event.currentTarget);
  };
  const axiosInstance = useAxiosInstance();

  const handleCloseMembers = (member: string | null) => {
    setMemberAnchorEl(null);
    if (member && !selectedMembers.some((m) => m.username === member)) {
      const memberId = memberIdMap[member];
      setSelectedMembers((prevMembers) => [
        ...prevMembers,
        { username: member, id: memberId },
      ]);
    }
  };

  const handleClickProject = (event: React.MouseEvent<HTMLElement>) => {
    setProjectAnchorEl(event.currentTarget);
  };

  const fetchUsernames = async () => {
    if (!selectedProject) return;

    try {
      const response = await axiosInstance.post("/api/get_all_user/", {
        entityId: app.entityId,
        projectId: projectIdMap[selectedProject.name],
      });
      const { isOk, message } = response.data;
      if (isOk && response.data.users) {
        const users: User[] = response.data.users;
        const idMap: { [key: string]: number } = {};
        users.forEach((user) => {
          idMap[user.username] = user.id;
        });
        setUsernames(users);
        setMemberIdMap(idMap);
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message || "Failed to fetch users",
            level: "error",
          })
        );
      }
    } catch (error: any) {
      console.error("Error fetching usernames:", error.message);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.post(apiURL + "/api/get_all_pro/", {
        entityId: app.entityId,
      });
      const { isOk, message } = response.data;
      if (isOk && response.data.projects) {
        const projects: Project[] = response.data.projects;
        setProjectList(projects);
        const idMap: { [key: string]: number } = {};
        projects.forEach((project) => {
          idMap[project.name] = project.id;
        });
        setProjectIdMap(idMap);
      } else {
        dispatch(
          uiActions.updateNotification({
            message: message || "Failed to fetch projects",
            level: "error",
          })
        );
      }
    } catch (error: any) {
      console.error("Error fetching projects:", error.message);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [dispatch, apiURL, app.entityId]);

  useEffect(() => {
    fetchUsernames();
  }, [selectedProject]);

  const handleCloseProject = (project: string | null) => {
    setProjectAnchorEl(null);
    if (project) {
      setSelectedProject({ name: project, id: projectIdMap[project] || 0 });
      setSelectedMembers([]); // Clear selected members
    }
  };

  const handleAdd = () => {
    if (!selectedProject) {
      console.error("Please select a project.");
      return;
    }

    const projectId = projectIdMap[selectedProject.name];
    const memberIds = selectedMembers.map((member) => member.id);

    const requestData = {
      projectId: projectId,
      memberIds: memberIds,
    };

    setIsLoading(true);

    axiosInstance
      .post("/api/add_project_members/", requestData)
      .then(() => {
        console.log("Members successfully added.");
        setSelectedProject(null);
        setSelectedMembers([]);
        setButtonText("ADD");
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <PageContainer>
        {" "}
        <Card className={styles.card}>
          <CardContent>
            <div className={styles.responsiveContainer}>
              <Button
                className={styles.menu}
                id="project-button"
                aria-controls={projectAnchorEl ? "project-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={projectAnchorEl ? "true" : undefined}
                onClick={handleClickProject}
                disabled={isLoading}
              >
                {selectedProject ? selectedProject.name : "Select Project"}
              </Button>
              <Menu
                id="project-menu"
                anchorEl={projectAnchorEl}
                open={Boolean(projectAnchorEl)}
                onClose={() => handleCloseProject(null)}
                MenuListProps={{
                  "aria-labelledby": "project-button",
                }}
              >
                {projectList.map((project) => (
                  <MenuItem
                    className={styles.me}
                    key={project.id}
                    onClick={() => handleCloseProject(project.name)}
                  >
                    {project.name}
                  </MenuItem>
                ))}
              </Menu>

              <Button
                className={styles.menu1}
                id="basic-button"
                aria-controls={memberAnchorEl ? "basic-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClickMember}
              >
                {selectedMembers.length === 0
                  ? "Select Members"
                  : selectedMembers.map((member, index) => (
                      <span key={index}>
                        {member.username}
                        {index < selectedMembers.length - 1 && ", "}
                      </span>
                    ))}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={memberAnchorEl}
                open={Boolean(memberAnchorEl)}
                onClose={() => handleCloseMembers(null)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {usernames.map((user) => (
                  <MenuItem
                    className={styles.me}
                    key={user.id}
                    onClick={() => handleCloseMembers(user.username)}
                  >
                    {user.username}
                  </MenuItem>
                ))}
              </Menu>

              <Button
                className={styles.btn}
                variant="contained"
                color="success"
                onClick={handleAdd}
                disabled={
                  isLoading || !selectedProject || selectedMembers.length === 0
                }
              >
                {isLoading ? "ADDING..." : buttonText}
              </Button>
            </div>
          </CardContent>
        </Card>
      </PageContainer>{" "}
    </>
  );
};

export default Approve;
